<template>
  <v-container>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Dateiname
            </th>
            <th class="text-left">
              Vorschau
            </th>
            <th class="text-left d-lg-table-cell d-none">
              URL (kleines Bild)
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- v-bind:key="item.fileName" -->
          <tr v-for="item in items"
              :key="item.fileName"
              @click="handleClick(item)"
              :class="{ selected: selectedFileName.includes(item.fileName) }"
          >
            <td>{{ item.fileName }}</td>
            <td>
              <div v-bind:style="{
                height:'50px',
                backgroundImage: `url('${item.fileUrl}')`,
                backgroundSize: 'contain'
                }">
              </div>
            </td>
            <td class="d-lg-table-cell d-none">{{ item.fileUrl.split("?")[0] }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn @click="loadMoreData" :right="true" :absolute="true">
      load data
    </v-btn>
  </v-container>
</template>

<style scoped>
  tr:hover {
    background-color: darkslategray !important;
  }
</style>

<script>

export default {
  name: 'ListView',
  mounted() {
    this.$nextTick(function init() {
      window.addEventListener('scroll', this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  emits: {
  },
  computed: {
  },
  methods: {
    selected(item) {
      console.log(`selected: ${item.fileName}`);
      // this.$router.push({
      //   name: 'ImageDetails',
      //   params: {
      //     smallFileName: item.fileName,
      //     smallFileUrl: item.fileUrl,
      //   },
      // });
    },
    handleClick(aItem) {
      console.log('handleClick()');
      // this.highlightClickedRow(item);
      // this.viewDetails(item);
      this.$router.push({
        name: 'ImageDetails',
        params: {
          item: aItem,
          lastView: 'listView',
        },
      });
    },
    highlightClickedRow(value) {
      const tr = value.target.parentNode;
      tr.classList.add('highlight');
    },
    onScroll() {
      // on page bottom load data
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.loadMoreData();
      }
    },
    loadMoreData() {
      console.log('listView, loadMoreData.');
      this.$emit('loadMoreData');
    },
  },
  props: {
    items: Array,
    loading: Boolean,
    selectedFileName: String,
  },
  data: () => ({
  }),
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Bild löschen
        </v-card-title>
        <v-card-text>Möchten Sie wirklich das Bild löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            @click="cancel"
          >
            <v-icon>mdi-arrow-left</v-icon>
            Abbrechen
          </v-btn>
          <v-btn
            color="red darken-1"
            @click="confirm"
          >
            <v-icon>mdi-delete-forever</v-icon>
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ConfirmDeleteDialog',
  mounted() {
    console.log('ConfirmDeleteDialog mounted:');
  },
  emits: {
    confirm: String,
  },
  computed: {},
  methods: {
    show() {
      this.dialog = true;
    },
    cancel() {
      this.dialog = false;
    },
    confirm() {
      this.$emit('confirm');
      this.dialog = false;
    },

  },
  props: {},
  data: () => ({
    dialog: false,
  }),
  watch: {
  },
};
</script>

<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app>
      <button title="Test"></button>
    </v-footer>
  </v-app>
</template>
<style scoped>
.v-application a {
  color: #fff;
  text-decoration: none;
}
a {
  outline: 0;
}
</style>
<script>

export default {
  props: {
    source: String,
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  mounted() {},
  methods: {
  },
  data: () => ({
    drawer: null,
    viewType: 1,
    propertyTypes: {
      suitability: { properties: null },
      mood: { properties: null },
      instrument: { properties: null },
    },
  }),
};
</script>

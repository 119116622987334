<template>
  <v-item-group>
    <v-container>
      <v-row>
        <v-col
          v-for="item in items"
          :key="item.fileName"
          xl="1"
          lg="2"
          md="3"
          sm="4"
          cols="6"
        >
          <v-item>
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="d-flex align-center"
                dark
                height="100px"
                @click="openImage(item)"
              >
                <v-img height="100%" :src="item.fileUrl">
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="pa-2 transition-fast-in-fast-out blue white--text"
                      style="height: 100%;">{{item.fileName}}
                    </div>
                  </v-expand-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-item>
        </v-col>
      </v-row>
      <v-btn @click="loadMoreData" :right="true" :absolute="true">
      load data
      </v-btn>
    </v-container>
  </v-item-group>
</template>

<style scoped>
  tr:hover {
    background-color: transparent !important;
  }
</style>

<script>

export default {
  name: 'CardView',
  mounted() {
    this.$nextTick(function init() {
      window.addEventListener('scroll', this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  emits: {
  },
  computed: {
  },
  methods: {
    openImage(aItem) {
      console.log('openImage()');
      // this.highlightClickedRow(item);
      // this.viewDetails(item);
      this.$router.push({
        name: 'ImageDetails',
        params: {
          item: aItem,
          lastView: 'cardView',
        },
      });
    },
    onScroll() {
      // on page bottom load data
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.loadMoreData();
      }
    },
    loadMoreData() {
      console.log('cardView, loadMoreData.');
      this.$emit('loadMoreData');
    },
  },
  props: {
    items: Array,
    headers: Array,
    selectedFileName: String,
  },
  data: () => ({
  }),
};
</script>

import Vue from 'vue';
import { LogLevel } from '@azure/msal-browser';
import msalPlugin from './plugins/msalPlugin';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

// globus azure : https://kind-plant-003e83503.azurestaticapps.net
// globus domain: https://bilderserver.globus.ch
const msalConfig = {
  auth: {
    clientId: '96458271-f54b-40cd-a36a-2f4fe0e514ee',
    authority: 'https://login.microsoftonline.com/ad.globus.ch/',
    redirectUri: (process.env.NODE_ENV === 'production')
      ? 'https://bilderserver.globus.ch'
      : 'http://localhost:8080',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
  mode: 'redirect',
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.error(message);
        }
      },
    },
  },
};

Vue.config.productionTip = false;
Vue.use(msalPlugin, msalConfig);

/* eslint-disable no-new */
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  created() {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn().then(() => {
        console.log('signIn Done.');
      });
    }
  },
}).$mount('#app');

<template>
  <v-dialog v-model="dialog" hide-overlay max-width="600">
    <v-card>
      <v-toolbar dark>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Bilder hochladen...</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-file-input
          small-chips
          multiple
          label="Dateien auswählen..."
          accept="image/*"
          v-model="files"
        ></v-file-input>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!showUploadButton" color="green darken-1" text @click="close">
          Schliessen
        </v-btn>
        <v-btn v-if="showUploadButton" color="green darken-1" text @click="close">
          Abbrechen
        </v-btn>
        <v-btn v-if="showUploadButton" color="green darken-1" text @click="onUpload"> OK </v-btn>
      </v-card-actions>
      <div class="pa-5">
        <v-alert v-if="message" border="left" color="blue-grey" dark>
          {{ message }}
        </v-alert>
        <div v-if="showProgress">
          <p>Fortschritt</p>
          <div
            class="mb-2"
            v-for="(progressInfo, index) in progressInfos"
            :key="index"
          >
            <span>{{ progressInfo.fileName }}</span>
            <v-progress-linear
              v-model="progressInfo.percentage"
              color="light-blue"
              height="25"
              reactive
            >
              <strong>{{ progressInfo.percentage }} %</strong>
            </v-progress-linear>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
tr:hover {
  background-color: transparent !important;
}
</style>

<script>
import api from '../api';

export default {
  name: 'FileUploadDialog',
  mounted() {
    console.log('FileUploadDialog mounted:');
  },
  emits: {},
  computed: {},
  methods: {
    show() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.progressInfos = [];
      this.showProgress = false;
      this.showUploadButton = true;
      this.files = [];
      this.message = '';
    },
    onUpload() {
      if (!this.files) {
        console.log('no file selected');
      } else {
        console.log('files:');

        this.message = '';
        this.showProgress = true;

        for (let i = 0; i < this.files.length; i += 1) {
          const file = this.files[i];
          console.log(file.name);
          this.upload(file, i);
        }

        console.log('upload finished.');
        this.showUploadButton = false;
        this.$emit('send', 'uploaded');
      }
    },
    upload(file, index) {
      this.progressInfos[index] = { percentage: 0, fileName: file.name };
      const me = this;

      const onProgress = function progress(event) {
        console.log('onProgress event:', event);
        me.progressInfos[index].percentage = Math.round((100 * event.loaded) / event.total);
      };

      if (this.$msal.isAuthenticated()) {
        // aquire api token
        this.$msal.readApiToken().then((accessToken) => {
          console.log('apiToken received.', accessToken);
          api.UploadImage(accessToken, file, onProgress)
            .then((response) => {
              console.log(`upload response ${response}`);

              if (response.data.erroredFiles && response.data.erroredFiles.length > 0) {
                throw response.data.erroredFiles;
              }

              if (me.message) {
                me.message += '\n';
              } else {
                me.message = '';
              }
              this.$forceUpdate();
              // me.close();
            })
            .catch((error) => {
              console.log('upload failed', error);
              me.progressInfos[index].percentage = 0;

              if (error[0].fileName) {
                me.message += `Upload fehlgeschlagen: ${error[0].fileName}`;
              } else {
                me.message += `Upload fehlgeschlagen: ${file.name}`;
              }
            });
        });
      }
    },
  },
  props: {},
  data: () => ({
    dialog: false,
    files: [],
    message: '',
    progressInfos: [],
    showProgress: false,
    showUploadButton: true,
  }),
  watch: {
    progressInfos: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
};
</script>

<template>
  <v-container>
    <v-toolbar fixed>
      <v-icon class="mr-5">mdi-file-image</v-icon>
      <!-- <v-toolbar-title>SAP Produktbild</v-toolbar-title> -->
      <v-toolbar-title class="">{{Title}}</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col sm="12">
        <!-- <h2>{{Title}}</h2> -->
        <v-spacer>&nbsp;</v-spacer>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col sm="6" md="4">
        <v-card height="100%" class="pa-15">
          <v-card-title>Kleines Bild</v-card-title>
          <v-card-subtitle>{{item.fileName}}</v-card-subtitle>
          <img :src="item.fileUrl"/>
          <br>
          <a :href="item.fileUrl">{{this.item.fileUrl.split("?")[0]}}</a>
        </v-card>
      </v-col>
      <v-col sm="6" md="8">
        <v-card class="pa-15">
          <v-card-title>Mittleres Bild</v-card-title>
          <v-card-subtitle>{{item.mediumFileName}}</v-card-subtitle>
          <img :src="item.mediumFileUrl" style="max-width: 100%;"/>
          <br>
          <a :href="item.mediumFileUrl">{{this.item.mediumFileUrl.split("?")[0]}}</a>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="green darken-1" @click="onBack">
          <v-icon>mdi-arrow-left</v-icon>
            Zurück
        </v-btn>
        <v-btn color="blue" class="ml-15" @click="onEdit">
          <v-icon>mdi-pencil</v-icon>
            Bearbeiten
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      <v-alert v-if="message" :value="message" :type="messageType">
        {{message}}
      </v-alert>
      </v-col>
    </v-row>
    <v-card v-if="isEditMode">
      <v-spacer></v-spacer>
      <v-row class="align-center justify-end">
        <v-col md="9" sm="12" cols="12">
          <v-text-field v-model="ImageSmallFileNameRenamed" outlined hide-details="">{{ImageSmallFileNameRenamed}}</v-text-field>
        </v-col>
        <v-col md="3" sm="5" cols="6">
          <v-btn color="orange" @click="onRenameFile" :disabled="ImageSmallFileNameRenamed==item.fileName">
            <v-icon>mdi-content-save</v-icon>
            Bild umbenennen
          </v-btn>
        </v-col>
      </v-row>
      <v-row  class="align-center justify-end">
        <v-col>
        </v-col>
        <v-col md="3" sm="5" cols="6">
          <v-btn color="red darken-1" @click="onDeleteFile">
            <v-icon>mdi-delete-forever</v-icon>
            Bild Löschen
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <ConfirmDeleteDialog ref="deleteConfirmDialog" @confirm="onDeleteFileConfirmed"/>
  </v-container>
</template>

<style scoped>
</style>

<script>
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog.vue';
import api from '../api';

export default {
  name: 'ImageDetails',
  mounted() {
    // read the 'props' from the router?
    if (!this.item.length <= 1) {
      if (!this.$route.params.item) {
        console.log('missing the item data, back to root.');
        this.onBack();
      }
      this.item = this.$route.params.item;
      this.lastView = this.$route.params.lastView;
      this.Title = this.item.fileName.replace(this.ImageSmallFileNamePrefix, '');
      this.Title = this.Title.replace(/\.[^/.]+$/, '');
    }
  },
  emits: {
  },
  computed: {
  },
  methods: {
    onEdit() {
      this.isEditMode = !this.isEditMode;
      this.ImageSmallFileNameRenamed = this.item.fileName;
    },
    onBack() {
      this.$router.push({
        name: 'Overview',
        params: {
          selectedFileName: this.smallFileName,
          lastView: this.lastView,
        },
      });
    },
    onRenameFile() {
      console.log('onRenameFile: ', this.ImageSmallFileNameRenamed);
      const newFileName = this.ImageSmallFileNameRenamed;

      if (newFileName.startsWith(this.ImageSmallFileNamePrefix)) {
        console.log('onRenameFile, Step1', newFileName);
        if (newFileName.endsWith('.jpg')) {
          console.log('onRenameFile, Step2');
          this.RenameFile();
        } else {
          this.message = 'Dateiname muss mit \'.jpg\' enden.';
        }
      } else {
        this.message = `Dateiname muss mit '${this.ImageSmallFileNamePrefix}' beginnen.`;
      }
    },
    RenameFile() {
      if (this.$msal.isAuthenticated()) {
        // aquire api token
        this.$msal.readApiToken().then((accessToken) => {
          console.log('apiToken received.', accessToken);
          api.RenameImage(accessToken, this.item.fileName, this.ImageSmallFileNameRenamed)
            .then((response) => {
              let message = '';
              if (response.response) {
                message = response.response;
              } else if (response.request) {
                message = response.request;
                if (response.request.response) {
                  message = response.request.response;
                }
              } else if (response.message) {
                message = response.message;
              }

              this.message = `Umbenennen erfolgreich: ${message}`;
              this.messageType = 'success';
              console.log(this.message);
              // navigate away
              setTimeout(() => {
                this.onBack();
              }, 1000);
            })
            .catch((error) => {
              console.log('catch error:');
              console.log(error);
              this.message = `Umbennnen fehlgeschlagen: ${error}`;
              this.messageType = 'warning';
            });
        });
      }
    },
    onDeleteFile() {
      // show dialog, will call onDeleteFileConfirmed()
      this.$refs.deleteConfirmDialog.show();
    },
    onDeleteFileConfirmed() {
      console.log('onDeleteFileConfirmed');
      this.DeleteFile();
    },
    DeleteFile() {
      if (this.$msal.isAuthenticated()) {
        // aquire api token
        this.$msal.readApiToken().then((accessToken) => {
          console.log('apiToken received.', accessToken);
          api.DeleteImage(accessToken, this.item.fileName)
            .then((response) => {
              let message = '';
              if (response.response) {
                message = response.response;
              } else if (response.request) {
                message = response.request;
                if (response.request.response) {
                  message = response.request.response;
                }
              } else if (response.message) {
                message = response.message;
              }

              this.message = `Löschen erfolgreich: ${message}`;
              this.messageType = 'success';
              console.log(this.message);
              // navigate away
              setTimeout(() => {
                this.onBack();
              }, 1000);
            })
            .catch((error) => {
              console.log('catch error:');
              console.log(error);
              this.message = `Löschen fehlgeschlagen: ${error}`;
              this.messageType = 'warning';
            });
        });
      }
    },
  },
  props: {
  },
  data: () => ({
    // replace hardcoded values?
    Title: '',
    ImageSmallFileNameRenamed: '',
    ImageSmallFileNamePrefix: 'MT0-',
    message: '',
    messageType: 'warning',
    isEditMode: false,
    lastView: 'listView',
    item: [],
  }),
  components: {
    ConfirmDeleteDialog,
  },
};
</script>

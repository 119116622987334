import Vue from 'vue';
import VueRouter from 'vue-router';
import Overview from '../views/Overview.vue';
import ImageDetails from '../views/ImageDetails.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Overview',
    component: Overview,
    props: {
      header: true,
      content: true,
    },
  },
  {
    path: '/details',
    name: 'ImageDetails',
    component: ImageDetails,
    props: {
      header: true,
      content: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes,
});

export default router;

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: (process.env.NODE_ENV === 'production')
    ? 'https://bawebilderserverapp.azurewebsites.net/api/'
    : 'http://localhost:7071/api/',
});

class api {
  static async BrowseImagesSimple(apiAccessToken) {
    return this.BrowseImages(apiAccessToken, 50, null);
  }

  // apiAccessToken,         Bearer Token valid for the API
  // itemsPerPageValue:      Pageing - Amount of files returned in one call
  // continuationTokenValue: Pageing - where to start (returned by last call)
  // namePrefix:             Filter the values (start of the filename only).
  static async BrowseImages(apiAccessToken, continuationTokenValue, namePrefix = null) {
    console.log('BrowseImages called.');
    const authBearer = `Bearer ${apiAccessToken}`;
    const itemsPerPageValue = 30;
    const axConfig = {
      headers: {
        Authorization: authBearer,
      },
      params: {
        itemsPerPage: itemsPerPageValue,
        continuationToken: continuationTokenValue,
        fileNamePrefix: namePrefix,
      },
    };
    return axiosInstance.get('BrowseImages', axConfig);
  }

  static async UploadImage(apiAccessToken, file, onUploadProgress) {
    console.log('UploadImages called.');
    const authBearer = `Bearer ${apiAccessToken}`;
    const axConfig = {
      headers: {
        Authorization: authBearer,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    };

    const formData = new FormData();
    formData.append('file', file);

    return axiosInstance.post('UploadImages', formData, axConfig);
  }

  static async DeleteImage(apiAccessToken, imageSmallFileName) {
    console.log('DeleteImage called.');
    const authBearer = `Bearer ${apiAccessToken}`;
    const axConfig = { headers: { Authorization: authBearer } };

    const fileData = {
      fileName: imageSmallFileName,
    };

    return axiosInstance.post('DeleteImage', fileData, axConfig);
  }

  static async RenameImage(apiAccessToken, imageSmallFileNameOld, imageSmallFileNameNew) {
    console.log('RenameImage called.');
    const authBearer = `Bearer ${apiAccessToken}`;
    const axConfig = { headers: { Authorization: authBearer } };

    const fileData = {
      fileNameOld: imageSmallFileNameOld,
      fileNameNew: imageSmallFileNameNew,
    };

    return axiosInstance.post('RenameImage', fileData, axConfig);
  }
}

export default api;

import * as msal from '@azure/msal-browser';
import Vue from 'vue';

// eslint-disable-next-line
let msalInstance = null;
// eslint-disable-next-line
let username = '';

const loginRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
};

// request for alg-dev azure functions Browse/Upload/Delete, account has to be set
// TODO: AAD App Registration, Change Manifest, "accessTokenAcceptedVersion": 2,
// eslint-disable-next-line
let apiRequest = {
  scopes: ['api://96458271-f54b-40cd-a36a-2f4fe0e514ee/api_access'],
  account: {},
};

export default class MsalPlugin extends msal.PublicClientApplication {
  static install(vue, msalConfig = {}) {
    msalInstance = new MsalPlugin(msalConfig, vue);
    // eslint-disable-next-line
    vue.prototype.$msal = msalInstance;    
    // create a global EventBus Object
    window.EventBus = new Vue();
  }

  constructor(option) {
    super(option);
    MsalPlugin.prototype.accountId = '';
  }

  handleResponse(response) {
    console.log('msalHandleResponse');

    if (response) {
      console.log('msalHandleResponse, login: ', response.account.username);
      this.accountId = response.account.homeAccountId;
      this.username = response.account.username;
      // Display signed-in user content, call API, etc.
    } else {
      console.log('msalHandleResponse, multiple Accounts');
      // In case multiple accounts exist, you can select
      const currentAccounts = this.getAllAccounts();

      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        this.loginRedirect(loginRequest);
      } else if (currentAccounts.length >= 1) {
        console.log('msalHandleResponse, login: ', currentAccounts[0].username);
        this.accountId = currentAccounts[0].homeAccountId;
        this.username = currentAccounts[0].username;
      }
    }
    // send event
    window.EventBus.$emit('signedIn', { data: this.username });
  }

  async signIn() {
    this.handleRedirectPromise()
      .then((response) => this.handleResponse(response));
  }

  async signOut() {
    this.logoutRedirect();
  }

  isAuthenticated() {
    let result = false;
    const activeAccount = this.getActiveAccount();
    const accounts = this.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      result = false;
    } else {
      result = true;
      this.username = accounts[0].username;
    }
    return result;
  }

  async readApiToken() {
    const accObj = this.getAccountByUsername(this.username);
    apiRequest.account = accObj;
    return this.getAccessToken(apiRequest);
  }

  // request: provide account and scope
  // return: access token
  async getAccessToken(request) {
    let accessToken = '';
    return this.acquireTokenSilent(request)
      .then((response) => {
        console.log('getSilentToken reponse:', response);
        accessToken = response.accessToken;
        return accessToken;
      })
      .catch((error) => {
        console.error(error);
        if (error.name === 'InteractionRequiredAuthError') {
          // fallback to interaction when silent call fails
          return this.acquireTokenRedirect(request)
            .then((response) => {
              console.log('acquireTokenRedirect reponse:', response);
              accessToken = response.accessToken;
              return accessToken;
            });
        }
        return 'some error';
      });
  }
}

export { msalInstance, username };

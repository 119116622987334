<template>
  <v-container>
    <v-app-bar fixed app>
      <!--
      <v-img class="hidden-md-and-down"
             contain
             src="Logo_Globus_01.svg"
             max-height="50"
             max-width="150">
      </v-img>
      -->
      <v-toolbar-title class="hidden-md-and-down">SAP Produktbilder</v-toolbar-title>

      <v-btn-toggle class="ml-5 hidden-xs-only" v-model="toggle">
        <v-btn>
          <v-icon>mdi-table-of-contents</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-table-large</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-text-field
        v-model="search"
        label="Suchen"
        @input="onSearch"
        clearable
        hide-details
        class="ml-10"
      ></v-text-field>

      <v-btn @click="showFileUploadDialog" class="ml-5" style="z-index: 5">
        <v-icon>mdi-upload</v-icon>
        <span class="hidden-lg-and-down">Upload</span>
      </v-btn>

      <v-btn class="ml-5" @click="signOff" :right="true" :absolute="true">
        <v-icon>mdi-logout</v-icon>
        <span class="hidden-md-and-down">{{ this.$msal.username }}</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- <v-navigation-drawer class="hidden-lg-and-down" clipped app
    >
      <br>
      <br>
      <br>
      <v-img src="@/assets/Logo_Globus_01.svg"></v-img>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="Logo_Globus_01"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->
    <ListView ref="listView" @loadMoreData="loadMoreData" :items="items" :selectedFileName="selectedFileName" v-if="toggle == 0"/>
    <CardView ref="cardView" @loadMoreData="loadMoreData" :items="items" :selectedFileName="selectedFileName" v-if="toggle == 1"/>
    <FileUploadDialog ref="fileUploadDialog" @send="fileUploadSend"/>
  </v-container>
</template>

<script>
import FileUploadDialog from '../components/FileUploadDialog.vue';
import CardView from '../components/CardView.vue';
import ListView from '../components/ListView.vue';
import api from '../api';

export default {
  name: 'Overview',
  mounted() {
    console.log('mounted was called.');

    // read the 'props' from the router?
    if (this.$route.params.lastView) {
      this.lastView = this.$route.params.lastView;
      console.log(`lastView: ${this.lastView}`);
    }

    if (this.$route.params.selectedFileName) {
      this.selectedFileName = this.$route.params.selectedFileName;
      console.log(`selectedFileName: ${this.selectedFileName}`);
    }

    if (this.lastView === 'cardView') {
      this.toggle = 1;
    }

    this.loadData();
  },
  created() {
    const self = this;
    window.EventBus.$on('signedIn', () => {
      console.log('signedIn received');
      self.onSignedOn();
    });
  },
  methods: {
    loadMoreData() {
      console.log('loadMoreData was called.');
      this.loadData();
    },
    loadData() {
      this.loading = true;
      console.log('loadData was called.');
      // search
      let searchString = null;
      if (this.search) {
        searchString = this.search;
      }
      // load
      const me = this;
      if (this.$msal.isAuthenticated()) {
        // aquire api token
        this.$msal.readApiToken().then((accessToken) => {
          console.log('apiToken received.', accessToken);
          api.BrowseImages(accessToken, me.continuationToken, searchString)
            .then((response) => {
              me.continuationToken = response.data.continuationToken;

              if (me.items == null) {
                me.items = response.data.images;
              } else {
                response.data.images.forEach((img) => {
                  if (!me.items.some((existing) => existing.fileName === img.fileName)) {
                  // if (me.items.indexOf(img) === -1) {
                    me.items.push(img);
                  }
                  // me.$set(me.items, img.fileName, img);
                });
              }
            });
        });
      }

      this.loading = false;
    },
    onSearch() {
      // clean previous results
      this.continuationToken = null;
      this.items = [];
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadData();
          this.awaitingSearch = false;
        }, 500); // delay, typing
      }
      this.awaitingSearch = true;
    },
    showFileUploadDialog() {
      this.$refs.fileUploadDialog.show();
    },
    fileUploadSend(data) {
      if (data === 'uploaded') {
        console.log('dialog upload is done, refresh.');
        this.loadData();
      }
    },
    handleClick() {
    },
    onSignedOn() {
      console.log('onSignedOn called.');
      this.username = this.$msal.username;
      this.loadData();
    },
    signOff() {
      const me = this;
      console.log(me.msal);
      me.$msal.signOut();
    },
  },
  data() {
    return {
      toggle: 0,
      search: '',
      awaitingSearch: false,
      headers: [
        {
          text: 'Dateiname',
          align: 'start',
          sortable: false,
          value: 'fileName',
          mobile: true,
        },
        {
          text: 'Vorschau',
          align: 'start',
          sortable: false,
          value: 'fileUrl',
        },
        {
          text: 'Adresse',
          align: 'start',
          sortable: false,
          value: 'fileUrl',
          mobile: false,
        },
      ],
      items: [
        // {
        //   fileName: 'Beispiel-Bild-1.jpg',
        //   fileUrl: '1234',
        // },
        // {
        //   fileName: 'Beispiel-Bild-2.jpg',
        //   fileUrl: '5678',
        // },
      ],
      continuationToken: '',
      selectedFileName: '',
      lastView: '',
    };
  },
  watch: {
    items: {
      handler() {
        // console.log('items have changed');
      },
      deep: true,
    },
    'this.$msal.username': {
      handler(newValue, oldValue) {
        console.log(`username old '${newValue}', new '${oldValue}'`);
      },
    },
  },
  components: {
    FileUploadDialog,
    CardView,
    ListView,
  },
};
</script>
